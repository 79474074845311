export const data = {
  sitemapTitle: "서비스이용약관 - 카카오 i 커넥트 라이브",
  html: `<section class="stackedit">
  <div class="stackedit__left">
    <div class="stackedit__toc">
      <ul>
        <li>
          <a href="#이용약관-2020-09-01-적용">이용약관 (2020-09-01 적용)</a>
          <ul>
            <li><a href="#환영합니다">환영합니다</a></li>
            <li><a href="#이-약관에-대하여">이 약관에 대하여</a></li>
            <li><a href="#정의">정의</a></li>
            <li>
              <a href="#리모트몬스터-서비스-이용">리모트몬스터 서비스 이용</a>
            </li>
            <li>
              <a href="#애플리케이션을-통한-제3자에-대한-서비스-제공">
                애플리케이션을 통한 제3자에 대한 서비스 제공
              </a>
            </li>
            <li>
              <a href="#귀하의-리모트몬스터-계정">귀하의 리모트몬스터 계정</a>
            </li>
            <li><a href="#서비스-이용-중지">서비스 이용 중지</a></li>
            <li>
              <a href="#리모트몬스터-서비스를-통해-유통되는-귀하의-콘텐츠">
                리모트몬스터 서비스를 통해 유통되는 귀하의 콘텐츠
              </a>
            </li>
            <li>
              <a
                href="#리모트몬스터-서비스를-통해-연결이-되고-3자간-직접-유통되는-콘텐츠"
              >
                리모트몬스터 서비스를 통해 연결이 되고 3자간 직접 유통되는
                콘텐츠
              </a>
            </li>
            <li><a href="#개인정보의-보호">개인정보의 보호</a></li>
            <li>
              <a href="#리모트몬스터-서비스에-포함된-소프트웨어에-대하여"
                >리모트몬스터 서비스에 포함된 소프트웨어에 대하여</a
              >
            </li>
            <li>
              <a href="#리모트몬스터-서비스의-수정-및-해지"
                >리모트몬스터 서비스의 수정 및 해지</a
              >
            </li>
            <li>
              <a href="#리모트몬스터의-보증-및-부인"
                >리모트몬스터의 보증 및 부인</a
              >
            </li>
            <li>
              <a href="#리모트몬스터-서비스에-대한-책임"
                >리모트몬스터 서비스에 대한 책임</a
              >
            </li>
            <li><a href="#분쟁">분쟁</a></li>
          </ul>
        </li>
        <li>
          <a href="#이용약관-2020-08-31-이전">이용약관 (2020-08-31 이전)</a>
        </li>
      </ul>
    </div>
  </div>
  <div class="stackedit__right">
    <div class="stackedit__html">
      <h1 id="이용약관-2020-09-01-적용">이용약관 (2020-09-01 적용)</h1>
      <h2 id="환영합니다">환영합니다</h2>
      <p>
        주식회사 카카오엔터프라이즈(이하 “회사”)가 제공하는 리모트몬스터
        서비스(이하 “본 서비스”)를 이용함으로써 귀하는 본 약관에 동의하게
        되므로 본 약관을 주의 깊게 읽어보시기 바랍니다.
      </p>
      <p>
        본 약관은 귀하가 리모트몬스터 웹 사이트와 본 서비스를 이용함에 있어
        조건, 절차, 권리 및 의무 등 제반 사항과 기타 필요 사항을 규정하는 것을
        목적으로 합니다.
      </p>
      <h2 id="이-약관에-대하여">이 약관에 대하여</h2>
      <p>
        본 약관의 내용은 본 서비스의 홈페이지 또는 본 서비스 화면에 게시하거나
        기타의 방법으로 귀하에게 공지합니다.
      </p>
      <p>
        회사는 법률의 변경이나 본 서비스의 변경사항을 반영하기 위한 목적
        등으로 본 약관을 수정할 수 있습니다. 귀하는 정기적으로 약관을 확인해야
        합니다. 본 페이지의 약관이 변경되는 경우 회사는 이에 대한 공지를
        게시합니다. 본 약관이 변경되는 경우 회사는 변경사항을 시행일자 7일
        전부터 공지하는 것을 원칙으로 하며, 피치 못하게 귀하에게 불리한
        내용으로 변경할 경우에는 그 시행일자 30일 전부터 공지하거나 개별적으로
        알려드리겠습니다. 단, 본 서비스에 대한 새로운 기능과 관련된 변경이나
        법률적인 사유로 인한 변경은 즉시 발효됩니다. 본 서비스에 대해 변경된
        약관에 동의하지 않는 경우 해당 서비스의 사용을 즉시 중지해야 하며,
        계속적인 사용은 약관에 동의한 것으로 봅니다.
      </p>
      <p>
        이 약관에 명시되지 않은 사항은 “정보통신망 이용촉진 및 정보보호 등에
        관한 법률”, “개인정보보호법”, “약관의 규제에 관한 법률”,
        “전기통신사업법” 등 관계 법령 및 회사가 제공하는 개별 서비스 이용
        약관, 이용 규정 또는 세부 지침 등을 따릅니다. 본 약관과 관련하여
        회사의 정책변경, 법령의 개정 또는 공공기관의 고시나 지침 등에 의하여
        회사가 공지사항 게시판 등을 통해 공지하는 내용도 이용약관 일부를
        구성합니다.
      </p>
      <p>
        리모트몬스터는 개별 서비스에 대해 별도의 계약을 할 수 있습니다. 본
        약관과 별도의 계약이 상충하는 경우, 상충하는 사항에 대하여 별도의
        계약이 적용됩니다.
      </p>
      <p>
        본 약관은 회사와 귀하와의 관계를 규율하며, 어떠한 제3자의 수익권도
        발생시키지 않습니다.
      </p>
      <p>
        귀하가 본 약관을 준수하지 않은 경우, 당사가 즉시 조치를 취하지
        않더라도 이는 당사가 가지고 있는 권리(향후 조치를 취하는 것 등)를
        포기함을 의미하지 않습니다.
      </p>
      <h2 id="정의">정의</h2>
      <ul>
        <li>
          <p>
            리모트몬스터 서비스 : 회사가 제공하는 WebRTC기반의
            통화/방송/그룹통화/화상회의 솔루션 및 플랫폼 서비스
          </p>
        </li>
        <li>
          <p>
            애플리케이션 : 리모트몬스터 서비스를 이용하여 고객이 만든
            소프트웨어
          </p>
        </li>
        <li>
          <p>
            리모트몬스터 SDK(Software Development Kit) : 애플리케이션에
            삽입되는 소프트웨어 모듈
          </p>
        </li>
        <li>
          <p>
            리모트몬스터 샘플코드(Sample Code) : 리모트몬스터 SDK를 이용하여
            회사가 만든 애플리케이션 예시
          </p>
        </li>
        <li>
          <p>
            리모트몬스터 계정 : 리모트몬스터 서비스 웹 콘솔에 로그인하기 위한
            정보를 의미하며, 주로 고객이 시크릿 키를 확인하거나, 서비스 이용을
            일시적으로 중지 하거나 또는 서비스의 이용량을 관리하는 경우 등에
            사용
          </p>
        </li>
        <li>
          <p>
            서비스 아이디 : 개별 애플리케이션마다 부여하는 애플리케이션 식별
            정보
          </p>
        </li>
        <li>
          <p>시크릿 키 : 서비스 아이디의 소유를 확인하기 위한 정보</p>
        </li>
      </ul>
      <h2 id="리모트몬스터-서비스-이용">리모트몬스터 서비스 이용</h2>
      <p>
        귀하는 본 서비스 내에서 적용되는 모든 정책을 준수해야 하며, 본
        서비스의 오용을 삼가시기 바랍니다. 예를 들어 본 서비스를 방해하거나 본
        서비스가 안내하는 API 및 가이드 외에 다른 방법을 사용하여 액세스를
        시도하지 않아야 합니다. 귀하는 오직 법률상 허용되는 범위에서만 본
        서비스를 이용할 수 있습니다. 귀하가 본 약관이나 정책을 준수하지 않거나
        회사가 귀하와 관련된 부정행위를 조사하고 있는 경우, 본 서비스 제공이
        일시 중지 또는 중단될 수 있습니다.
      </p>
      <p>
        본 서비스에 대한 저작권 등 지식재산권은 회사에 귀속됩니다. 귀하가 본
        서비스를 이용한다고 해서 본 서비스 또는 액세스하는 콘텐츠의
        지식재산권을 소유하게 되는 것은 아닙니다. 본 서비스에 표시된 제3자
        소유의 콘텐츠의 경우 콘텐츠 소유자로부터 허가를 받거나 달리 법률에
        따라 허용되는 경우를 제외하고, 귀하가 이를 사용할 수 없습니다. 본
        약관은 귀하에게 본 서비스에 사용된 브랜드나 로고를 사용할 권리를
        부여하지 않습니다. 본 서비스 내에 또는 본 서비스와 함께 게재된 어떠한
        법적 고지도 삭제하거나 가리거나 변경하지 마십시오.
      </p>
      <p>
        본 서비스는 회사가 소유하지 않은 일부 콘텐츠를 표시할 수 있습니다.
        그러한 콘텐츠에 대해서는 제공한 주체가 단독으로 책임지게 됩니다.
        회사는 콘텐츠의 위법성 여부 또는 본 서비스 정책 위반 여부를 판단하기
        위해 콘텐츠를 검토할 수 있으며, 콘텐츠가 본 서비스 정책 또는 법률에
        위반된다고 합리적으로 판단하는 경우 이를 삭제하거나 게시를 거부할 수
        있습니다. 이는 회사가 반드시 콘텐츠를 검토한다는 의미가 아닙니다.
      </p>
      <p>
        본 서비스 이용과 관련하여 회사는 귀하에게 본 서비스 고지, 관리 메시지
        및 기타 정보를 발송할 수 있습니다. 귀하는 메시지 수신을 거부할 수
        있습니다.
      </p>
      <h2 id="애플리케이션을-통한-제3자에-대한-서비스-제공">
        애플리케이션을 통한 제3자에 대한 서비스 제공
      </h2>
      <p>
        귀하는 자신의 영업을 위하여 본 서비스를 응용하여 애플리케이션을 만들어
        제3자에게 서비스를 제공 할 수 있습니다. 회사는 제3자에게 제공되는
        귀하의 서비스에 책임을 지지 않습니다. 귀하는 관계법령에 의거하여
        제3자에 대한 서비스를 운영하고 정당한 계약을 통해 제3자의 권익을
        보호하여야 합니다. 본 약관에 의거하여 본 서비스 운영이 귀하의 제3자
        제공 서비스에 영향을 준다 하더라도 회사는 어떠한 책임도 없음을
        고지합니다. 귀하가 제3자에게 제공하는 서비스는 본 약관에 의거하여
        이용중지, 해약, 손해배상등의 조치가 취해질 수 있으며, 이러한 조치가
        설사 귀하의 제3자 제공 서비스에 영향을 준다 하더라도 회사는 책임이
        없음을 고지합니다. 귀하는 자신의 영업 목적이 아닌 제3자의 사업을
        위하여 애플리케이션을 만들 경우 회사의 동의를 구해야합니다.
      </p>
      <h2 id="귀하의-리모트몬스터-계정">귀하의 리모트몬스터 계정</h2>
      <p>
        귀하가 본 서비스를 이용하기 위해서는 리모트몬스터 계정이 필요합니다.
        리모트몬스터 계정 발급을 위해서 회사가 정한 계정발급양식을 작성하고
        일정시간 후 회사가 이를 승낙하는 방식으로 이루어 집니다. 귀하는
        계정신청양식을 성실히 작성하여야 합니다. 회사는 필요에 따라 추가적인
        정보를 요청하거나 사실확인을 할 수 있으며 승낙을 유보, 거절 할 수
        있습니다. 신청양식이 허위로 밝혀질 시 사전통보 없이 즉각적으로 사용을
        중지하고 허위에 따른 손해배상을 청구할 수 있습니다. 귀하가
        리모트몬스터 계정을 직접 만들 수도 있고, 고용주 또는 교육기관과 같은
        관리자가 귀하에게 리모트몬스터 계정을 배정할 수도 있습니다. 관리자가
        배정한 리모트몬스터 계정을 사용하고 있는 경우, 별도의 약관 또는
        추가약관이 적용될 수 있으며 관리자가 귀하의 계정에 액세스하거나 계정을
        해지할 수 있습니다.
      </p>
      <p>
        리모트몬스터 계정을 보호하려면 비밀번호를 비공개로 유지하십시오.
        귀하는 리모트몬스터 계정에서 또는 리모트몬스터 계정을 통해 이루어지는
        활동에 대한 책임이 있습니다. 귀하의 리모트몬스터 계정이 무단으로
        사용되고 있음을 알게 되는 경우 회사에 즉각적인 연락을 취하여야 합니다.
      </p>
      <p>
        일부 서비스는 계정가입을 통해 시크릿 키를 발급받고 시크릿키를 통해서
        서비스를 이용합니다. 귀하는 이 시크릿 키를 각별히 주의하여
        관리해야하며 이를 통해 이루어지는 활동에 대한 책임이 있습니다.
      </p>
      <h2 id="서비스-이용-중지">서비스 이용 중지</h2>
      <p>
        회사는 다음의 경우 사전통보 없이 귀하에 대하여 즉각적인 서비스
        이용해지나 서비스의 전부, 일부를 중지 할 수 있습니다.
      </p>
      <ul>
        <li>
          <p>
            타인의 명의를 사용하거나 타인의 이메일, 휴대전화 등의 개인정보
            또는 허위의 정보를 사용하여 리모트몬스터 계정을 신청하거나
            변경하는 경우
          </p>
        </li>
        <li>
          <p>
            회사에 의하여 이용계약이 해지된 날로부터 6개월 이내에 재이용신청을
            하는 경우
          </p>
        </li>
        <li>
          <p>기술상 서비스 제공이 불가능한 경우</p>
        </li>
        <li>
          <p>
            계정발급에 필요한 등록 사항을 누락하거나 오기하여 신청하는 경우
          </p>
        </li>
        <li>
          <p>
            귀하가 회사로부터 자격정지 조치 등을 받은 그 조치 기간에
            이용계약을 임의 해지하고 재이용신청을 하는 경우
          </p>
        </li>
        <li>
          <p>정당한 이유 없이 리모트몬스터 계정을 공유한 경우</p>
        </li>
        <li>
          <p>
            정당한 이유 없이 서비스 아이디 또는 시크릿 키를 타인에게 공유한
            경우
          </p>
        </li>
        <li>
          <p>
            기타 회사가 정한 이용신청 요건을 만족하지 않았거나 위법 또는
            부당한 이용신청임이 확인된 경우 또는 귀하의 책임 있는 사유로
            인하여 회사가 승낙할 수 없는 경우
          </p>
        </li>
        <li>
          <p>타인의 정보를 도용하는 행위</p>
        </li>
        <li>
          <p>
            회사가 게시한 정보의 변경, 복제, 유통, 조작하거나 상업적으로
            이용하는 행위
          </p>
        </li>
        <li>
          <p>
            관련 법령에 의하여 그 전송 또는 게시가 금지되는
            정보(컴퓨터프로그램 포함)의 복제, 전송 또는 게시 행위
          </p>
        </li>
        <li>
          <p>
            회사와 기타 제3자의 지식재산권, 초상권 등 기타 권리에 대한 침해
            행위
          </p>
        </li>
        <li>
          <p>회사 및 기타 제3자의 명예를 손상하거나 업무를 방해하는 행위</p>
        </li>
        <li>
          <p>
            외설 또는 폭력적인 메시지, 화상, 음성, 기타 미풍양속에 반하는
            정보를 서비스에 공개 또는 게시하는 행위
          </p>
        </li>
        <li>
          <p>회사의 동의 없이 영리를 목적으로 서비스를 사용하는 행위</p>
        </li>
        <li>
          <p>
            회사의 승인을 받지 않고 다른 이용자의 개인 정보를 수집, 저장,
            유포, 게시하는 행위
          </p>
        </li>
        <li>
          <p>프로그램의 버그를 악용하는 행위</p>
        </li>
        <li>
          <p>
            회사의 서비스 운영을 고의로 방해하거나 서비스의 안정적 운영을
            방해할 수 있는 행위
          </p>
        </li>
        <li>
          <p>
            회사의 직원이나 운영자를 가장하거나 사칭 또는 타인의 명의를
            도용하여 글을 게시하거나 E-mail을 발송하는 행위
          </p>
        </li>
        <li>
          <p>서비스를 모방하거나 회사의 브랜드를 모방, 도용하는 행위</p>
        </li>
        <li>
          <p>
            서비스의 출처표시 용도 외에 회사의 상표를 무단으로 사용하거나
            모방하는 행위
          </p>
        </li>
        <li>
          <p>
            기타 사회의 안녕질서, 공서양속을 저해하거나, 저해할 목적으로
            서비스를 이용하거나, 불법적, 부당한 행위 및 관계 법령에 어긋나는
            행위
          </p>
        </li>
      </ul>
      <h2 id="리모트몬스터-서비스를-통해-유통되는-귀하의-콘텐츠">
        리모트몬스터 서비스를 통해 유통되는 귀하의 콘텐츠
      </h2>
      <p>
        귀하는 본 서비스를 통하여 콘텐츠를 업로드, 제출, 저장, 전송, 수신할 수
        있으며 이에 대해 귀하가 보유한 지식재산권 등은 귀하에게 귀속됩니다.
      </p>
      <p>
        귀하가 본 서비스로 또는 이를 통해 귀하의 콘텐츠를 업로드, 제출, 저장,
        전송 또는 수신하는 경우 귀하는 회사(및 회사의 협력사)에게 이러한
        콘텐츠를 사용, 저장, 복제, 수정, 이차적 저작물(귀하의 콘텐츠가 본
        서비스를 통해 더 잘 구현되도록 회사가 생성하는 번역본, 개작본, 또는
        수정본으로 인해 발생되는 것) 제작, 전달, 공개, 공개적으로 실연,
        공개적으로 게시 및 배포할 수 있는 전 세계적인 라이선스를 제공하게
        됩니다. 본 라이선스에서 귀하가 부여하는 권리는 회사가 본 서비스를
        운영, 홍보 및 개선하고 새로운 서비스를 개발하기 위한 제한적인 목적으로
        사용됩니다. 본 라이선스는 귀하가 본 서비스의 사용을 중지 또는 종료한
        후에도 존속됩니다(예: 귀하가 작성한 회사 게시판의 질의 정보). 귀하는
        본 서비스에 제출한 콘텐츠에 대해 회사에 라이선스를 부여하기 위해
        필요한 권리를 보유해야 합니다.
      </p>
      <p>
        회사는 귀하에게 유용한 서비스 기능을 제공할 목적으로 귀하의 콘텐츠를
        분석합니다. 이러한 분석은 귀하의 콘텐츠 전송, 수신, 저장의 경우에
        한하여 수행됩니다.
      </p>
      <p>
        회사가 귀하의 콘텐츠를 사용 및 저장하는 방법에 대해 좀 더 자세한
        정보가 필요한 경우, 리모트몬스터 개인정보처리방침 또는 특정 서비스에
        대한 추가약관을 참조하시기 바랍니다. 귀하가 서비스에 대한 의견이나
        제안을 제출하는 경우 회사는 귀하에 대한 어떤 추가적인 의무를 부담하지
        않고귀하의 의견이나 제안을 사용할 수 있습니다.
      </p>
      <h2
        id="리모트몬스터-서비스를-통해-연결이-되고-3자간-직접-유통되는-콘텐츠"
      >
        리모트몬스터 서비스를 통해 연결이 되고 3자간 직접 유통되는 콘텐츠
      </h2>
      <p>
        리모트몬스터의 일부 서비스는 귀하가 제3자들간에 콘텐츠 연결(다수의
        제3자들간의 연결 포함)을 수행하도록 할 수 있습니다. 이러한 연결에서
        회사는 연결 내용이나 콘텐츠를 살펴보지 않습니다. 이러한 연결에서
        회사는 연결 방식이나 연결 품질등 기능 확인을 위한 내용을 수집할 수
        있습니다. 이러한 연결에서 사용 또는 발생되는 콘텐츠의 저작권과 사용은
        귀하와 제3자간 약정, 계약에 의거하며 회사와는 어떠한 법률적 관련이
        없음을 고지합니다. 단, 본 약관에 의거하여 본 약관을 벗어나는 본 서비스
        이용시 이용중지, 해약, 손해배상등의 조치가 취해질 수 있습니다.
      </p>
      <h2 id="개인정보의-보호">개인정보의 보호</h2>
      <p>
        귀하의 개인정보의 안전한 처리는 회사에게 있어 가장 중요한 일 중
        하나입니다. 귀하의 개인정보는 서비스의 원활한 제공을 위하여 귀하가
        동의한 목적과 범위 내에서만 이용됩니다. 회사가 귀하의 개인정보를
        안전하게 처리하기 위하여 기울이는 노력이나 기타 자세한 사항은 회사의
        개인정보처리방침 등을 참고해 주시기 바랍니다.
      </p>
      <h2 id="리모트몬스터-서비스에-포함된-소프트웨어에-대하여">
        리모트몬스터 서비스에 포함된 소프트웨어에 대하여
      </h2>
      <p>
        회사는 귀하에게 본 서비스의 일부로 제공하는 리모트몬스터 SDK와
        리모트몬스터 샘플코드를 사용할 수 있도록 라이선스를 제공합니다. 귀하는
        회사가 제공한 대로 본 서비스를 이용해야합니다. 귀하는 회사의
        서면허가를 받은 경우를 제외하고, 본 서비스 또는 이에 포함된
        소프트웨어의 일부를 복사, 수정, 배포, 판매, 양도, 담보제공 또는 대여할
        수 없으며, 소프트웨어를 역설계하거나 소스 코드의 추출을 시도하는 등 본
        서비스를 복제, 분해 또는 모방하거나 기타 변형하는 일체의 행위를 할 수
        없습니다.
      </p>
      <p>
        본서비스에서 사용되는 일부 소프트웨어는 오픈소스 라이선스에 따라
        제공될 수 있습니다. 오픈소스 라이선스에는 본 약관의 일부 규정에
        우선하는 규정이 있을 수 있습니다.
      </p>
      <h2 id="리모트몬스터-서비스의-수정-및-해지">
        리모트몬스터 서비스의 수정 및 해지
      </h2>
      <p>
        회사는 본 서비스를 지속적으로 변경 및 개선하고 있습니다. 회사는 기능을
        추가 또는 제거할 수 있으며, 그에 따라 본 서비스를 일시 중지하거나
        완전히 중단할 수 있습니다.
      </p>
      <p>
        회사에게는 매우 안타까운 일이지만, 귀하는 언제라도 본 서비스 이용을
        중지할 수 있습니다. 회사 또한 언제든지 본 서비스 제공을 중단하거나 본
        서비스에 대해 새로운 제한을 추가하거나 만들 수 있습니다.
      </p>
      <p>
        회사는 귀하의 사용 신청, 사용 방식, 사용 시 유통되는 콘텐츠의 내용에
        따라 귀하의 본 서비스에 대한 사용을 일방적으로 중지시킬 수 있습니다.
      </p>
      <h2 id="리모트몬스터의-보증-및-부인">리모트몬스터의 보증 및 부인</h2>
      <p>
        회사는 상업적으로 합리적인 수준의 기술을 사용하고 주의를 기울여 본
        서비스를 제공하며, 귀하가 본 서비스를 유용하게 이용하기를 바랍니다.
        단, 서비스에 대하여 약속할 수 없는 몇 가지 사항이 있습니다.
      </p>
      <p>
        본 약관 또는 별도의 계약에 명시되지 않은 한 회사, 또는 회사로부터
        권한을 위임 받은 본 서비스의 판매자는 서비스와 관련하여 어떠한
        구체적인 약정도 하지 않습니다. 예를 들어, 회사는 본 서비스에 속한
        콘텐츠, 본 서비스의 특정 기능, 서비스의 신뢰성, 이용가능성 또는 귀하의
        요구를 충족할 능력에 대하여 어떠한 약정도 하지 않습니다.
      </p>
      <p>회사는 법률상 허용되는 한도 내에서 모든 보증을 배제합니다.</p>
      <h2 id="리모트몬스터-서비스에-대한-책임">
        리모트몬스터 서비스에 대한 책임
      </h2>
      <p>
        회사 및 회사로부터 권한을 위임 받은 본 서비스의 판매자(이하 “회사 및
        판매자”)는 과실이 없이 발생된 아래와 같은 손해에 대해서는 책임을
        부담하지 않습니다. 또한 법률상 허용되는 경우, 회사 및 판매자는
        일실이익, 일실수입, 망실자료, 재무적 손실, 간접 손해, 특별 손해,
        결과적 손해, 징계적 손해, 또는 징벌적 손해에 대한 책임을 부담하지
        않습니다. 법률상 허용되는 한도 내에서, 묵시적 보증을 포함하여 본
        약관에 따른 청구와 관련하여 회사 및 판매자의 총 책임은 귀하가 본
        서비스 사용을 위해 회사에 지급한 금원(또는 회사의 선택에 따라 귀하에게
        본 서비스를 다시 제공하는 것)으로 제한됩니다.
      </p>
      <ul>
        <li>
          <p>무료서비스의 이용으로 인한 손해</p>
        </li>
        <li>
          <p>
            천재지변 또는 이에 준하는 불가항력(정전, 제반 설비의 장애 혹은
            이용량 폭주로 인해 정상적인 서비스 이용에 지장을 주는 상황 등)으로
            인해 본 서비스를 제공할 수 없는 경우
          </p>
        </li>
        <li>
          <p>귀하의 귀책사유로 인한 본 서비스 이용의 장애</p>
        </li>
        <li>
          <p>
            기간통신 사업자가 전기통신서비스를 중지하거나 정상적으로 제공하지
            아니하여 발생하는 손해
          </p>
        </li>
        <li>
          <p>
            귀하와 귀하의 애플리케이션 사용자의 컴퓨터 환경으로 인하여
            발생하는 제반 문제 또는 회사의 귀책사유가 없는 네트워크 환경으로
            인하여 발생하는 문제
          </p>
        </li>
        <li>
          <p>귀하의 시스템 보안 관리 소홀로 인하여 발생하는 손해</p>
        </li>
        <li>
          <p>
            귀하의 애플리케이션의 문제로 인하여 개인정보가 유출되어 발생한
            손해
          </p>
        </li>
        <li>
          <p>
            제3자가 불법적으로 회사의 서버에 접속하거나 서버를 이용함으로써
            발생한 손해
          </p>
        </li>
        <li>
          <p>
            제3자가 회사 서버에 대한 전송 또는 회사 서버로부터의 전송을
            방해함으로써 발생하는 손해
          </p>
        </li>
        <li>
          <p>제3자가 악성 프로그램을 전송 또는 유포함으로써 발생하는 손해</p>
        </li>
        <li>
          <p>기타 회사의 고의 또는 과실이 없는 사유로 인해 발생한 손해</p>
        </li>
      </ul>
      <p>
        회사는 귀하와 제3자 상호 간 서비스를 매개로 발생한 분쟁에 대해 개입할
        의무가 없으며, 이에 따른 손해에 대해서도 배상할 책임이 없습니다. 만일
        귀하와 분쟁 중인 제3자가 회사를 상대로 이의를 제기할 경우, 귀하는
        자신의 비용과 책임으로 회사를 면책시켜야 합니다.
      </p>
      <p>
        회사는 본 서비스를 매개로 귀하가 제작한 애플리케이션의 이용 혹은
        그러한 애플리케이션과 연계된 서비스와 관련하여 게재한 정보, 자료,
        사실의 신뢰도, 정확성 등의 내용에 관하여는 책임을 지지 않습니다. 또한
        귀하의 애플리케이션을 통해 획득한 자료로 인한 제3자의 손해 등에
        대해서도 책임을 지지 않습니다.
      </p>
      <p>
        회사는 본 서비스 화면에서 링크, 배너 등을 통하여 연결된 제3자와 귀하
        간에 이루어진 거래에 개입하지 않으며, 해당 거래에 대하여 책임을 지지
        않습니다.
      </p>
      <p>
        귀하는 이용신청 시 기재한 사항이 변경되었을 경우에는 즉시 변경사항을
        최신의 정보로 수정하여야 합니다. 수정하지 않은 정보로 인하여 발생하는
        손해는 귀하가 부담하며, 회사는 이에 대하여 책임을 지지 않습니다.
      </p>
      <p>
        본 서비스는 귀하의 애플리케이션 사용 환경 등에 따라 정상적으로
        동작하지 않거나 회사가 예견하지 못한 오류가 존재할 수 있으며, 본
        서비스의 사용 불능으로 인하여 귀하에게 발생한 손해에 대하여 책임을
        지지 않습니다.
      </p>
      <h2 id="분쟁">분쟁</h2>
      <p>
        본 약관 또는 본 서비스와 관련하여 발생하는 분쟁에 대해서는 대한민국의
        법률이 적용됩니다. 본 서비스 이용과 관련하여 회사와 귀하 간에 분쟁이
        발생하면 이의 해결을 위해 성실히 협의할 것입니다. 그럼에도 불구하고
        해결되지 않으면 민사소송법상의 관할법원에 소를 제기할 수 있습니다.
      </p>
      <hr />
      <h1 id="이용약관-2020-08-31-이전">이용약관 (2020-08-31 이전)</h1>
      <p><strong>리모트몬스터에 오신것을 환영합니다</strong></p>
      <p>
        서비스를 이용함으로써 귀하는 본 약관에 동의하게 되므로 본 약관을 주의
        깊게 읽어보시기 바랍니다.
      </p>
      <p>
        본 약관은 귀하가 리모트몬스터의 홈페이지와 서비스를 이용함에 있어 조건
        및 절차, 권리와 의무 등 제반 사항과 기타 필요 사항을 규정하는 것을
        목적으로 합니다.
      </p>
      <p><strong>이 약관에 대하여</strong></p>
      <p>
        본 약관의 내용은 서비스의 홈페이지 또는 서비스 화면에 게시하거나
        기타의 방법으로 귀하에게 공지합니다.
      </p>
      <p>
        리모트몬스터은 법률의 변경이나 서비스의 변경사항을 반영하기 위한 목적
        등으로 본 약관이나 서비스에 적용되는 추가약관을 수정할 수 있습니다.
        귀하는 정기적으로 약관을 확인해야 합니다. 본 페이지의 약관이 변경되는
        경우 리모트몬스터는 이에 대한 공지를 게시합니다. 리모트몬스터은
        추가약관의 변경 공지를 해당 서비스에 게재할 것입니다. 당해 변경은
        소급되어 적용되지 않으며 게시하고 14일 이후에 발효됩니다. 단, 서비스에
        대한 새로운 기능과 관련된 변경이나 법률적인 사유로 인한 변경은 즉시
        발효됩니다. 서비스에 대해 변경된 약관에 동의하지 않는 경우 해당
        서비스의 사용을 중지해야 합니다. 변경고지된 내용에 동의하지 않으면
        즉시 사용을 중지하여야 하며, 계속적인 사용은 약관에 동의한 것으로
        봅니다.
      </p>
      <p>
        이 약관에 명시되지 않은 사항은 “정보통신망 이용촉진 및 정보보호 등에
        관한 법률”, “약관의 규제에 관한 법률”, “전기통신사업법” 등 관계 법령
        및 회사가 제공하는 개별 서비스 이용 약관, 이용 규정 또는 세부 지침
        등을 따릅니다. 본 약관과 관련하여 회사의 정책변경, 법령의 개정 또는
        공공기관의 고시나 지침 등에 의하여 회사가 공지사항 게시판 등을 통해
        공지하는 내용도 이용약관 일부를 구성합니다.
      </p>
      <p>
        리모트몬스터는 개별 서비스에 대해 별도의 추가약관을 둘 수 있으며 해당
        서비스를 사용시 본 약관과 함께 추가약관에 대한 동의를 하여야 합니다.
        본 약관과 추가약관이 상충하는 경우, 상충하는 사항에 대하여 추가약관이
        적용됩니다.
      </p>
      <p>
        본 약관은 리모트몬스터과 귀하와의 관계를 규율하며, 어떠한 제3자의
        수익권도 발생시키지 않습니다.
      </p>
      <p>
        귀하가 본 약관을 준수하지 않은 경우, 당사가 즉시 조치를 취하지
        않더라도 이는 당사가 가지고 있는 권리(향후 조치를 취하는 것 등)를
        포기함을 의미하지 않습니다.
      </p>
      <p><strong>리모트몬스터 서비스 이용</strong></p>
      <p>귀하는 서비스 내에서 적용되는 모든 정책을 준수해야 합니다.</p>
      <p>
        리모트몬스터 서비스의 오용을 삼가시기 바랍니다. 예를 들어 서비스를
        방해하거나 리모트몬스터가 제공하는 인터페이스 및 안내사항 이외의 다른
        방법을 사용하여 액세스를 시도하지 않아야 합니다. 귀하는 오직 법률상
        허용되는 범위에서만 리모트몬스터 서비스를 이용할 수 있습니다. 귀하가
        리모트몬스터 약관이나 정책을 준수하지 않거나 리모트몬스터이 부정행위
        혐의를 조사하고 있는 경우, 리모트몬스터 서비스 제공이 일시 중지 또는
        중단될 수 있습니다.
      </p>
      <p>
        리모트몬스터 서비스를 사용한다고 해서 리모트몬스터 서비스 또는
        액세스하는 콘텐츠의 지적재산권을 소유하게 되는 것은 아닙니다. 콘텐츠
        소유자로부터 허가를 받거나 달리 법률에 따라 허용되는 경우를 제외하고,
        리모트몬스터 서비스의 콘텐츠를 사용할 수 없습니다. 본 약관은 귀하에게
        리모트몬스터 서비스에 사용된 브랜드나 로고를 사용할 권리를 부여하지
        않습니다. 리모트몬스터 서비스에 또는 리모트몬스터 서비스와 함께 게재된
        어떠한 법적 고지도 삭제하거나 감추거나 변경하지 마십시오.
      </p>
      <p>
        리모트몬스터 서비스는 리모트몬스터이 소유하지 않은 일부 콘텐츠를
        표시할 수 있습니다. 그러한 콘텐츠에 대해서는 제공한 주체가 단독으로
        책임지게 됩니다. 리모트몬스터은 콘텐츠의 위법성 여부 또는 리모트몬스터
        정책 위반 여부를 결정하기 위해 검토할 수 있으며, 콘텐츠가 리모트몬스터
        정책 또는 법률에 위반된다고 합리적으로 판단하는 경우 이를 삭제하거나
        게시를 거부할 수 있습니다. 그렇다고 반드시 콘텐츠를 검토한다는 의미는
        아니므로, 콘텐츠를 검토할 것이라고 간주하지 마십시오.
      </p>
      <p>
        서비스 이용과 관련하여 리모트몬스터은 귀하에게 서비스 고지, 관리
        메시지 및 기타 정보를 발송할 수 있습니다. 귀하는 메시지 수신을 거부할
        수 있습니다.
      </p>
      <p><strong>리모트몬스터를 통한 서비스 제공</strong></p>
      <p>
        귀하는 리모트몬스터의 서비스를 응용하여 제3자에게 서비스를 제공 할 수
        있습니다. 제 3자에게 제공되는 서비스는 리모트몬스터와 어떠한 법률적
        연관이 없으며, 귀하는 관계법령에 의거하여 제3자에 대한 제3자제공
        서비스를 운영하고 정당한 계약을 통해 제3자의 권익을 보호하여야 합니다.
        본 약관에 의거하여 리모트몬스터의 서비스 운영이 귀하의 제3자 제공
        서비스에 영향을 준다 하더라도 리모트몬스터는 어떠한 책임도 없음을
        고지합니다. 귀하가 제 3자에게 제공되는 서비스는 리모트몬스터의 본
        이용약관에 의거하여 이용중지, 해약, 손해배상등의 조치가 취해질 수
        있으며, 이러한 조치가 설사 귀하의 제3자 제공 서비스에 영향을 준다
        하더라도 어떠한 책임도 리모트몬스터는 없음을 고지합니다.
      </p>
      <p><strong>귀하의 리모트몬스터 계정</strong></p>
      <p>
        리모트몬스터는 별도 가입이 없이 곧장 사용가능 한 서비스가 일부
        제공되며, 이러한 사용시 리모트몬스터는 귀하께 본 약관을 공지합니다.
        귀하는 공지된 약관을 충분히 검토 후 동의하면 서비스를 사용하여야 하며,
        본 약관에 동의하지 않으면 지체없이 사용을 중지하여야 합니다.
      </p>
      <p>
        귀하가 리모트몬스터 서비스를 이용하기 위해서는 리모트몬스터 계정이
        필요할 수 있습니다. 계정 발급을 위해서 리모트몬스터가 정한
        계정발급양식을 작성하고 일정시간 후 리모트몬스터가 이를 승낙하는
        방식으로 이루어 집니다. 귀하는 계정신청양식을 성실히 작성하여야
        합니다. 리모트몬스터는 필요에 따라 추가적인 정보를 요청하거나
        사실확인을 할 수 있으며 승낙을 유보, 거절 할 수 있습니다. 신청양식이
        허위로 밝혀질 시 사전통보 없이 즉각적으로 사용을 중지되고 허위에 따른
        손해를 청구할 수 있습니다. 귀하가 리모트몬스터 계정을 직접 만들 수도
        있고, 고용주 또는 교육기관과 같은 관리자가 귀하에게 리모트몬스터
        계정을 배정할 수도 있습니다. 관리자가 배정한 리모트몬스터 계정을
        사용하고 있는 경우, 별도의 약관 또는 추가약관이 적용될 수 있으며
        관리자가 귀하의 계정에 액세스하거나 계정을 해지할 수 있습니다.
      </p>
      <p>
        리모트몬스터 계정을 보호하려면 비밀번호를 비공개로 유지하십시오.
        귀하는 리모트몬스터 계정에서 또는 리모트몬스터 계정을 통해 이루어지는
        활동에 대한 책임이 있습니다. 타사 애플리케이션에서 리모트몬스터 계정
        비밀번호를 재사용하지 마십시오. 귀하의 비밀번호나 리모트몬스터 계정이
        무단으로 사용되고 있음을 알게 되는 경우 리모트몬스터에 즉각적인 연락을
        취하여야 합니다.
      </p>
      <p>
        일부 서비스는 계정가입을 통해 인증키를 발급받고 인증키를 통해서
        서비스를 이용합니다. 귀하는 이 인증키를 각별히 주의하여 관리해야하며
        이를 통해 이루어지는 활동에 대한 책임이 있습니다.
      </p>
      <p><strong>서비스 이용 중지</strong></p>
      <p>
        리모트몬스터는 다음과 같은 사항에 의해 사전통보 없이 귀하에게 즉각적인
        서비스 이용해지나 서비스의 전부, 일부를 중지 할 수 있습니다.
      </p>
      <ul>
        <li>
          다른 사람의 명의를 사용하거나 다른 사람의 이메일, 휴대전화 등의
          개인정보를 사용하여 회원 등록 시 허위로 신청하는 경우
        </li>
        <li>
          리모트몬스터에 의하여 이용계약이 해지된 날로부터 6개월 이내에
          재이용신청을 하는 경우
        </li>
        <li>기술상 서비스 제공이 불가능한 경우</li>
        <li>고객 등록 사항을 누락하거나 오기하여 신청하는 경우</li>
        <li>
          귀하가 리모트몬스터로부터 자격정지 조치 등을 받은 그 조치 기간에
          이용계약을 임의 해지하고 재이용신청을 하는 경우
        </li>
        <li>
          사회의 안녕질서 또는 미풍양속을 저해하거나, 저해할 목적으로 신청한
          경우
        </li>
        <li>
          기타 리모트몬스터가 정한 이용신청 요건이 만족하지 않았거나 위법 또는
          부당한 이용신청임이 확인된 경우 또는 귀하의 책임 있는 사유로 인하여
          리모트몬스터가 승낙할 수 없는 경우
        </li>
        <li>신청 또는 변경 시 허위 내용을 등록하는 행위</li>
        <li>타인의 정보를 도용하는 행위</li>
        <li>
          리모트몬스터가 게시한 정보의 변경, 복제, 유통, 조장하거나 상업적으로
          이용하는 행위
        </li>
        <li>
          리모트몬스터가 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신
          또는 게시하는 행위
        </li>
        <li>
          리모트몬스터와 기타 제3자의 지적 재산권, 초상권 등 기타 권리에 대한
          침해 행위
        </li>
        <li>
          리모트몬스터 및 기타 제3자의 명예를 손상하거나 업무를 방해하는 행위
        </li>
        <li>
          외설 또는 폭력적인 메시지, 화상, 음성, 기타 미풍양속에 반하는 정보를
          서비스에 공개 또는 게시하는 행위
        </li>
        <li>
          리모트몬스터의 동의 없이 영리를 목적으로 서비스를 사용하는 행위
        </li>
        <li>
          리모트몬스터의 승인을 받지 않고 다른 회원의 개인 정보를 수집, 저장,
          유포, 게시하는 행위
        </li>
        <li>프로그램의 버그를 악용하는 행위</li>
        <li>
          리모트몬스터 서비스 운영을 고의로 방해하거나 서비스의 안정적 운영을
          방해할 수 있는 행위
        </li>
        <li>
          리모트몬스터의 직원이나 운영자를 가장하거나 사칭 또는 타인의 명의를
          도용하여 글을 게시하거나 E-mail을 발송하는 행위
        </li>
        <li>서비스를 모방하거나 회사의 브랜드를 모방, 도용하는 행위</li>
        <li>
          서비스의 출처표시 용도 외에 회사의 상표를 무단으로 사용하거나
          모방하는 행위
        </li>
        <li>
          기타 공공질서 및 미풍양속을 위반하거나 불법적, 부당한 행위 및 관계
          법령에 어긋나는 행위
        </li>
      </ul>
      <p><strong>개인정보 보호 및 저작권 보호</strong></p>
      <p>
        리모트몬스터 개인정보취급방침은 귀하가 리모트몬스터 서비스를 사용할 때
        리모트몬스터이 개인정보를 어떻게 취급하고 보호하는지에 대해
        설명합니다. 리모트몬스터 서비스를 사용함으로써 귀하는 리모트몬스터가
        별도로 정의한 "개인정보취급방침"에 따라 귀하의 개인정보를 사용할 수
        있음에 동의하게 됩니다.
      </p>
      <p>
        리모트몬스터은 법에 규정된 절차에 따라 저작권침해를 주장하는 신고에
        대응하고, 반복 침해자의 계정을 해지합니다.
      </p>
      <p>
        리모트몬스터은 저작권자가 온라인상에서 자신의 지적 재산을 관리할 수
        있도록 정보를 제공합니다. 누군가 귀하의 저작권을 침해하고 있다고
        생각되어 리모트몬스터에 통지하고자 하는 경우, 리모트몬스터 도움말
        센터에서 신고서 제출 방법 및 저작권 침해 신고에 대한 리모트몬스터 대응
        정책 관련 정보를 확인하실 수 있습니다.
      </p>
      <p>
        <strong>리모트몬스터 서비스를 통해 유통되는 귀하의 콘텐츠</strong>
      </p>
      <p>
        귀하는 일부 리모트몬스터 서비스를 중계하여 콘텐츠를 업로드, 제출,
        저장, 전송, 수신할 수 있으며 이에 대해 귀하가 보유한 지적재산권은
        귀하의 소유입니다. 즉, 귀하가 보유한 권리는 귀하에게 존속됩니다.
      </p>
      <p>
        귀하가 콘텐츠를 리모트몬스터 서비스로 또는 이를 통해 업로드, 제출,
        저장, 전송 또는 수신하는 경우 귀하는 리모트몬스터(및 리모트몬스터의
        협력사)이 이러한 콘텐츠를 사용, 저장, 복제, 수정, 이차적 저작물(귀하의
        콘텐츠가 리모트몬스터 서비스와 더 잘 작동하도록 리모트몬스터이
        생성하는 번역본, 개작본, 또는 수정본으로 인해 발생되는 것) 제작, 전달,
        공개, 공개적으로 실연, 공개적으로 게시 및 배포할 수 있는 전 세계적인
        라이선스를 제공하게 됩니다. 본 라이선스에서 귀하가 부여하는 권리는
        리모트몬스터 서비스를 운영, 홍보 및 개선하고 새로운 서비스를 개발하기
        위한 제한적인 목적으로 사용됩니다. 본 라이선스는 귀하가 리모트몬스터
        서비스의 사용을 중지한 후에도 존속됩니다(예: 리모트몬스터 게시판에
        질의 정보). 일부 서비스에서는 제공한 콘텐츠에 액세스하고 이를 삭제하는
        방법을 제공할 수 있습니다. 귀하는 리모트몬스터 서비스에 제출한
        콘텐츠에 대해 리모트몬스터에 라이선스를 부여하기 위해 필요한 권리를
        보유해야 합니다.
      </p>
      <p>
        리모트몬스터는 귀하에게 유용한 제품 기능을 제공할 목적으로 귀하의
        콘텐츠(이메일 포함)를 분석합니다. 이러한 분석은 콘텐츠 전송, 수신,
        저장 시에 수행됩니다.
      </p>
      <p>
        리모트몬스터이 콘텐츠를 사용 및 저장하는 방법에 대해 좀 더 자세한
        정보가 필요한 경우, 리모트몬스터 개인정보취급방침 또는 특정 서비스에
        대한 추가약관을 참조하시기 바랍니다. 귀하가 리모트몬스터 서비스에 대한
        의견이나 제안을 제출하는 경우 리모트몬스터은 귀하에 대한 의무 없이
        귀하의 의견이나 제안을 사용할 수 있습니다.
      </p>
      <p>
        <strong
          >리모트몬스터 서비스를 통해 연결이 되고 3자간 직접 유통되는
          콘텐츠</strong
        >
      </p>
      <p>
        리모트몬스터의 일부 서비스는 귀하가 제3자와 또다른 다수 혹은 단독의
        제3자간 콘텐츠 연결을 수행하도록 할 수 있습니다. 이러한 연결에서
        리모트몬스터는 연결 내용이나 콘텐츠를 살펴보지 않습니다. 이러한
        연결에서 리모트몬스터는 연결 방식이나 연결 품질등 기능 확인을 위한
        내용을 수집할 수 있습니다. 이러한 연결에서 사용되는, 발생되는 콘텐츠의
        지적제산권과 사용은 귀하와 제3자간 약정, 계약에 의거하며
        리모트몬스터와는 어떠한 법률적 관련이 없음을 고지합니다. 단, 본
        이용약관에 의거하여 약관을 벗어나는 서비스 이용시 이용중지, 해약,
        손해배상등의 조치가 취해질 수 있습니다.
      </p>
      <p><strong>리모트몬스터 서비스에 포함된 소프트웨어에 대하여</strong></p>
      <p>
        리모트몬스터은 귀하에게 서비스의 일부로 제공하는 소프트웨어를 사용할
        수 있도록 개인적이고 전 세계적이며 양도불가능하고 비독점적인 무상
        라이선스를 제공합니다. 이 라이선스는 본 약관에 따라 귀하가
        리모트몬스터이 제공한 대로 서비스를 사용하고 혜택을 누릴 수 있도록
        하기 위한 목적으로만 제공됩니다. 귀하는 법률상 이와 같은 제한이
        금지되거나 리모트몬스터의 서면허가를 받은 경우를 제외하고,
        리모트몬스터 서비스 또는 이에 포함된 소프트웨어의 일부를 복사, 수정,
        배포, 판매 또는 대여할 수 없으며, 소프트웨어를 역설계하거나 소스
        코드의 추출을 시도할 수 없습니다.
      </p>
      <p>
        리모트몬스터에서 오픈 소스 소프트웨어는 매우 중요한 의미를 가집니다.
        리모트몬스터 서비스에서 사용되는 일부 소프트웨어는 귀하에게 제공하는
        오픈 소스 라이선스에 따라 제공될 수 있습니다. 오픈 소스 라이선스에는
        명시적으로 본 약관의 일부 규정에 우선하는 규정이 있을 수 있습니다.
      </p>
      <p><strong>리모트몬스터 서비스의 수정 및 해지</strong></p>
      <p>
        리모트몬스터은 서비스를 지속적으로 변경 및 개선하고 있습니다.
        리모트몬스터은 기능을 추가 또는 제거할 수 있으며, 서비스를 일시
        중지하거나 완전히 중단할 수 있습니다.
      </p>
      <p>
        리모트몬스터로서는 매우 안타까운 일이지만, 귀하는 언제라도
        리모트몬스터 서비스 이용을 중지할 수 있습니다. 리모트몬스터 또한
        언제든지 서비스 제공을 중단하거나 리모트몬스터 서비스에 대해 새로운
        제한을 추가하거나 만들 수 있습니다.
      </p>
      <p>
        리모트몬스터는 귀하의 사용 신청, 사용 방식, 사용시 유통되는 컨텐츠의
        내용에 때라 귀하의 리모트몬스터 서비스에 대한 사용을 일방적으로 해지할
        수 있습니다.
      </p>
      <p><strong>리모트몬스터의 보증 및 부인</strong></p>
      <p>
        리모트몬스터은 상업적으로 합리적인 수준의 기술을 사용하고 주의를
        기울여 서비스를 제공하며, 귀하가 서비스를 누리게 되길 바랍니다. 단,
        서비스에 대하여 약속할 수 없는 몇 가지 사항이 있습니다.
      </p>
      <p>
        본 약관 또는 추가약관에 명시되지 않은 한 리모트몬스터, 또는
        리모트몬스터의 공급자나 판매자는 서비스와 관련하여 어떠한 구체적인
        약정도 하지 않습니다. 예를 들어, 리모트몬스터은 서비스에 속한 콘텐츠,
        서비스의 특정 기능, 서비스의 신뢰성, 이용가능성 또는 귀하의 요구를
        충족할 능력에 대하여 어떠한 약정도 하지 않습니다. 리모트몬스터는
        서비스를 ‘있는 그대로’ 제공합니다.
      </p>
      <p>
        리모트몬스터은 법률상 허용되는 한도 내에서 모든 보증을 배제합니다.
      </p>
      <p><strong>리모트몬스터 서비스에 대한 책임</strong></p>
      <p>
        법률상 허용되는 경우, 리모트몬스터, 리모트몬스터의 공급자 및 판매자는
        일실이익, 일실수입, 망실자료, 재무적 손실, 간접 손해, 특별 손해,
        결과적 손해, 징계적 손해, 또는 징벌적 손해에 대한 책임을 부담하지
        않습니다.
      </p>
      <p>
        법률상 허용되는 한도 내에서, 묵시적 보증을 포함하여 본 약관에 따른
        청구에 대한 리모트몬스터, 리모트몬스터의 공급자 및 판매자의 총 책임은
        귀하가 서비스 사용을 위해 리모트몬스터에 지급한 금원(또는
        리모트몬스터의 선택에 따라 귀하에게 서비스를 다시 제공하는 것)으로
        제한됩니다.
      </p>
      <p>
        어떤 경우에도 리모트몬스터는 합리적으로 예측 불가능한 손실이나 손해에
        대해 책임지지 않습니다.
      </p>
      <p>
        리모트몬스터는 천재지변 또는 이에 준하는 불가항력(정전, 제반 설비의
        장애 혹은 이용량 폭주로 인해 정상적인 서비스 이용에 지장을 주는 상황
        등)으로 인해 서비스를 제공할 수 없는 경우, 서비스 제공에 관한 책임을
        면하게 됩니다.<br />
        리모트몬스터는 귀하의 귀책사유로 인한 서비스 이용의 장애에 대하여는
        책임을 지지 않습니다.<br />
        리모트몬스터는 어떠한 경우에도 귀하가 서비스 이용을 통해 기대하는 수익
        상실에 대해 책임을 지지 않으며, 서비스에 대한 취사선택 또는 이용으로
        발생하는 손해 등에 대해서는 책임을 지지 않습니다.<br />
        리모트몬스터는 귀하와 제3자 상호 간 서비스를 매개로 발생한 분쟁에 대해
        개입할 의무가 없으며, 이에 따른 손해에 대해서도 배상할 책임이
        없습니다. 만일 귀하와 분쟁 중인 제3자가 회사를 상대로 이의를 제기할
        경우, 귀하는 리모트몬스터를 자신의 비용과 책임으로 면책시켜야
        합니다.<br />
        리모트몬스터는 회원이 서비스와 관련하여 게재한 정보, 자료, 사실의
        신뢰도, 정확성 등의 내용에 관하여는 책임을 지지 않습니다. 또한
        서비스를 통해 획득한 자료로 인한 손해 등에 대해서도 책임을 지지
        않습니다<br />
        리모트몬스터는 회원 간 또는 회원과 제3자 상호간에 서비스를 매개로 하여
        거래 등을 한 경우에는 책임이 면제됩니다.<br />
        리모트몬스터는 기간통신 사업자가 전기통신서비스를 중지하거나
        정상적으로 제공하지 아니하여 손해가 발생한 경우에는 책임을 면하게
        됩니다.<br />
        리모트몬스터는 회원의 컴퓨터 환경으로 인하여 발생하는 제반 문제 또는
        회사의 귀책사유가 없는 네트워크 환경으로 인하여 발생하는 문제에
        대해서는 일체 책임을 지지 않습니다.<br />
        리모트몬스터는 무료로 제공되는 서비스 이용과 관련하여 관련법에 특별한
        규정이 없는 한 책임을 지지 않습니다<br />
        리모트몬스터는 회원의 약관, 서비스별 안내, 기타 회사가 정한 이용기준을
        준수하지 않은 이용으로 인한 결과에 대하여 책임을 지지 않습니다.<br />
        리모트몬스터는 상품 또는 정보 등을 통해 표현하거나 드러나는 회원의
        견해와 회사의 견해는 무관하며, 리모트몬스터는 회원이 제공하는 상품
        또는 정보 등에 대하여 어떠한 책임도 부담하지 아니합니다.<br />
        리모트몬스터는 서비스 화면에서 링크, 배너 등을 통하여 연결된 회사(이하
        피 연결회사)와 회원 간에 이루어진 거래에 회사는 개입하지 않으며, 해당
        거래에 대하여 책임을 지지 않습니다.<br />
        리모트몬스터는 회원의 귀책사유로 인해 노출된 회원 정보에 대해서 일체의
        책임을 지지 않습니다.<br />
        리모트몬스터는 이용신청 시 기재한 사항이 변경되었을 경우에는 즉시
        변경사항을 최신의 정보로 수정하여야 합니다. 수정하지 않은 정보로
        인하여 발생하는 손해는 당해 회원이 부담하며, 회사는 이에 대하여 아무런
        책임을 지지 않습니다.<br />
        서비스는 회원 또는 회원이 제공하는 서비스의 회원 사용 환경 등에 따라
        정상적으로 동작하지 않거나 회사가 예견하지 못한 오류가 존재할 수
        있으며, 리모트몬스터는 서비스의 사용 불능으로 인하여 회원에게 발생한
        손해에 대하여 책임을 지지 않습니다.
      </p>
      <p><strong>분쟁</strong></p>
      <p>
        본 약관 또는 서비스와 관련하여 발생하는 분쟁에 대해서는 대한민국의
        법률이 적용됩니다. 본 약관 또는 서비스와 관련하여 발생되는 모든 소송은
        독점적으로 대한민국 서울중앙지방법원에서 다루어지며 귀하와
        리모트몬스터은 이러한 법원이 인적 관할을 갖는 것에 동의합니다.
      </p>
    </div>
  </div>
  </section>`
};
